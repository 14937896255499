export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            serials: [],
            ledgers: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        serials(state) {
            return state.serials.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        ledgers(state) {
            return state.ledgers.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },

    mutations: {
        loading(state, isLoading) {
            state.loading = isLoading;
        },

        setSerial(state, serials) {
            state.serials = serials;
        },
        setLedger(state, ledgers) {
            state.ledgers = ledgers;
        },
    },

    actions: {
        async getSerial(context, payload) {
            context.commit('loading', true);

            let serials = await axios.post(`${this.state.host}/get-serials`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.serials;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            context.commit('setSerial', serials);

            context.commit('loading', false);
        },
        async getLedgers(context, payload) {
            context.commit('loading', true);

            let ledgers = await axios.post(`${this.state.host}/get-serial-ledgers`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.ledgers;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            context.commit('setLedger', ledgers);

            context.commit('loading', false);
        },
    }
}
